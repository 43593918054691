import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, _mergeProps(_ctx.$attrs, {
    value: _ctx._value,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._value) = $event)),
    placeholder: "请输入",
    class: "select-wrapper"
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandArray, (item) => {
        return (_openBlock(), _createBlock(_component_a_select_option, {
          key: item.id,
          value: item.brandNameEn
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.brandNameEn), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["value"]))
}