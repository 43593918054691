
import { defineComponent, ref } from "vue";
import { DealerRetailHistoryData } from "@/views/RetailValidation/Dealer/types";
import { getRetailHistory } from "@/API/rv/rvDealer";
import { useStore } from "vuex";
import moment from "moment";
const columns = [
  { title: "车架号", dataIndex: "vin", width: 150 },
  {
    title: "零售类型",
    dataIndex: "oasisUsageHistoryCn",
    width: 120
  },
  {
    dataIndex: "customerInvoiceDate",
    width: 130,
    slots: { title: "invoiceDateTitle", customRender: "customerInvoiceDate" }
  },
  {
    dataIndex: "acquisitionInvoiceDate",
    width: 160,
    slots: { title: "invoiceBillingDateTitle", customRender: "acquisitionInvoiceDate" }
  },
  {
    dataIndex: "registrationDate",
    width: 130,
    slots: { title: "licenseDateTitle", customRender: "registrationDate" }
  },
  {
    dataIndex: "acquisitionRegistrationDate",
    width: 160,
    slots: { title: "licenseBillingDateTitle", customRender: "invoiceDate" }
  },
  {
    dataIndex: "specialUsage",
    width: 130,
    slots: { title: "specialUsageTitle" }
  },
  {
    title: "车辆零售状态",
    dataIndex: "vehicleCount",
    width: 100,
    slots: { customRender: "vehicleCount" }
  }
];
export default defineComponent({
  setup() {
    const data = ref<DealerRetailHistoryData[]>([]);
    const title = ref<string>("");
    const current = ref<number>(1);
    const size = ref<number>(20);
    const total = ref<number>(0);
    const dealerCode = useStore().state.user.organization.entityCode;
    const initData = () => {
      const params = {
        vin: sessionStorage.getItem("detailRecordVinNo"),
        page: current.value - 1,
        size: size.value
      };
      getRetailHistory({ params }).then(res => {
        data.value = res.content;
        total.value = res.totalElements;
      });
    };
    title.value =
      sessionStorage.getItem("detailRecordEntity") === "MBCL" ||
      sessionStorage.getItem("detailRecordEntity") === "BBAC"
        ? "CESAR"
        : sessionStorage.getItem("detailRecordEntity") === "FBAC"
        ? "SWT-DFE"
        : "DOS";
    initData();
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      initData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      initData();
    };

    const usageText = (oasisUsage: string) => {
      if (oasisUsage === 'End Customer' || oasisUsage === '最终用户') {
        return '最终用户用车'
      }
      if (oasisUsage === 'Company Car' || oasisUsage === '经销商用车') {
        return '经销商用车'
      }
      return ''
    }

    return {
      columns,
      data,
      title,
      pageChange,
      sizeChange,
      total,
      size,
      usageText,
      moment
    };
  }
});
