
import { defineComponent, PropType, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { findBrandByBu } from '@/API/rv/rvDealer';
import useModel from '@/hooks/useModel';

export default defineComponent({
    props: {
        value: {
            type: [String, Array] as PropType<any | any[]>,
        },
    },
    emits: ['data-loaded'],
    setup(props, { emit }) {
        const _value = useModel(props, 'value');
        const brandRes = ref();
        const brandArray = computed(() => {
            return brandRes.value;
        });
        const { state } = useStore();
        const getBrandArray = () => {
            findBrandByBu(
                state.user?.buList.map((item: { id: number }) => item.id) ?? []
            ).then((res: any) => {
                const brands = new Map();
                for (const buBrands of res) {
                    for (const brand of buBrands.brandList) {
                        if (!brands.has(brand.id)) {
                            brands.set(brand.id, brand);
                        }
                    }
                }
                brandRes.value = Array.from(brands.values());
                const brandNames = brandRes.value.map((ele: { brandNameEn: string }) => {
                    return ele.brandNameEn;
                });
                sessionStorage.setItem('brandRes', brandNames);
                emit('data-loaded', brandNames);
            });
        };
        getBrandArray();
        return {
            brandArray,
            _value,
        };
    },
});
