
import { defineComponent, ref, watch } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
import { getWithdrawComletedInfo } from '@/API/rv/rvDealer';
import WithdrawCallbackUpload from '@/views/RetailValidation/Dealer/WithdrawCallbackUpload.vue';
type Key = ColumnProps['key'];

const columnsAll = [
    {
        title: '',
        dataIndex: 'taName',
        key: 'taName',
        width: 150,
        //ellipsis: true
    },
    {
        title: '零售数量',
        dataIndex: 'retailCount',
        key: 'retailCount',
        width: 150,
    },
    {
        title: '零售指标',
        dataIndex: 'retailTarget',
        key: 'retailTarget',
        width: 150,
    },
    {
        title: '零售完成率',
        dataIndex: 'completeSincere',
        key: 'completeSincere',
        width: 150,
        //slots: { customRender: "completeSincere" }
    },
    {
        title: '撤回影响',
        dataIndex: 'withdrawInfluence',
        key: 'withdrawInfluence',
        width: 150,
        //slots: { customRender: "withdrawInfluence" }
    },
];

export default defineComponent({
    name: 'SelectProgramConfirm',
    components: {
        WithdrawCallbackUpload,
    },
    props: {
        modalVisible: {
            type: Boolean,
            default: false,
            required: true,
        },
        recordId: {
            type: Number,
            default: -1,
            required: true,
        },
        vinNo: {
            type: String,
            default: '',
            required: true,
        },
        retailDate: {
            type: String,
            default: '',
            required: true,
        },
        brand: {
            type: String,
            default: '',
            required: true,
        },
    },
    emits: ['update:modalVisible'],
    setup(props, { emit }) {
        // const withdrawCompletedVisible = ref((props as any).modalVisible);
        const status = ref<string>('');
        const columns = ref<any[]>([]);
        const dataSourceQuarter = ref([]);
        const dataSourceMonthly = ref([]);

        const getInfo = () => {
            getWithdrawComletedInfo(props.recordId).then((res: any) => {
                console.log('车辆撤回零售完成率影响情况接口返回结果：', res);
                if (res.code === '0') {
                    dataSourceMonthly.value = res.data.monthOverViews;
                    dataSourceQuarter.value = res.data.quarterOverViews;
                } else {
                    message.error(res.message);
                }
            });
        };

        const withdrawCallbackUploadVisible = ref<boolean>(false);
        const handlerConfirm = (type: string): void => {
            // withdrawCompletedVisible.value = false;
            emit('update:modalVisible', false);
            withdrawCallbackUploadVisible.value = true;
        };

        watch(
            () => props.modalVisible,
            (newVal) => {
                if (newVal) {
                    getInfo();
                }
            },
            { immediate: true }
        );

        return {
            columns,
            columnsAll,
            //rowSelection,
            handlerConfirm,
            dataSourceQuarter,
            dataSourceMonthly,
            status,
            // withdrawCompletedVisible,
            withdrawCallbackUploadVisible,
        };
    },
});
