
import { defineComponent, ref, reactive, computed, onUnmounted } from 'vue'
import type { PropType } from 'vue'
export default defineComponent({
  props: {
    src: {
      type: String
    },
    height: {
      type: [String, Number] as PropType<string|number>
    },
    width: {
      type: [String, Number] as PropType<string|number>
    }
  },
  setup(props) {
    const preview = ref(false)
    const zoom = ref(1)
    const translate = reactive({
      x: 0,
      y: 0,
    })
    const translateOrigin = reactive({
      x: 0,
      y: 0,
    })
    const rotate = ref(0)
    const origin = {
      x: 0,
      y: 0
    }
    let fixed = true

    const ableToZoomIn = computed(() => {
      return zoom.value !== 1
    })

    const handleZoomIn = () => {
      if (zoom.value !== 1) {
        zoom.value --
      }
    }

    const handleZoomOut = () => {
      zoom.value ++
    }

    const handleRotateRight = () => {
      rotate.value += 90
    }

    const handleRotateLeft = () => {
      rotate.value -= 90
    }

    const imageWidth = computed(() => {
      if (typeof props.width === 'number') {
        return `${props.width}px`
      }
      return props.width
    })

    const imageHeight = computed(() => {
      if (typeof props.height === 'number') {
        return `${props.height}px`
      }
      return props.height
    })

    const handlePreview = () => {
      fixed = true
      translate.x = 0
      translate.y = 0
      translateOrigin.x = 0
      translateOrigin.y = 0
      preview.value = true
    }

    const handleClose = () => {
      if (fixed) {
        preview.value = false
      }
    }

    const handleMouseDown = (event: MouseEvent) => {
      fixed = false
      origin.x = event.pageX
      origin.y = event.pageY
    }

    const handleMouseMove = (event: MouseEvent) => {
      if (!fixed) {
        translate.x = translateOrigin.x + event.pageX - origin.x
        translate.y = translateOrigin.y + event.pageY - origin.y
      }
    }

    const handleMouseUp = (event: MouseEvent) => {
      fixed = true
      translateOrigin.x = translate.x
      translateOrigin.y = translate.y
    }

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('mousemove', handleMouseMove)

    onUnmounted(() => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mousemove', handleMouseUp)
    })

    return {
      preview,
      zoom,
      translate,
      rotate,
      ableToZoomIn,
      imageWidth,
      imageHeight,
      handleZoomIn,
      handleZoomOut,
      handleRotateRight,
      handleRotateLeft,
      handlePreview,
      handleClose,
      handleMouseDown,
    }
  }
})
