
import { defineComponent, ref, computed, toRaw } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
import { postCallbackUpload, getAllBrands, findBuByBrand } from '@/API/rv/rvDealer';
import { FileInfo } from '@/types';
import { FileItem } from '@/API/types';
import { getManagerApi } from '@/API/claim/delaerClaim/delaer';
import { useStore } from 'vuex';
type Key = ColumnProps['key'];

const columnsAll = [
  {
    title: '',
    dataIndex: 'taName',
    key: 'taName',
    width: 150
    //ellipsis: true
  },
  {
    title: '零售数量',
    dataIndex: 'retailCount',
    key: 'retailCount',
    width: 150
  },
  {
    title: '零售指标',
    dataIndex: 'retailTarget',
    key: 'retailTarget',
    width: 150
  },
  {
    title: '零售完成率',
    dataIndex: 'completeSincere',
    key: 'completeSincere',
    width: 150,
    slots: { customRender: 'completeSincere' }
  },
  {
    title: '撤回影响',
    dataIndex: 'withdrawInfluence',
    key: 'withdrawInfluence',
    width: 150,
    slots: { customRender: 'withdrawInfluence' }
  }
];

export default defineComponent({
  name: 'SelectProgramConfirm',
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    recordId: {
      type: Number,
      default: -1,
      required: true
    },
    vinNo: {
      type: String,
      default: '',
      required: true
    },
    retailDate: {
      type: String,
      default: '',
      required: true
    },
    brand: {
      type: String,
      default: '',
      required: true
    }
  },
  emits: ['update:withdrawModelVisible', 'confirm'],
  setup(props, content) {
    const withdrawCallbackUploadVisible = (props as any).modalVisible;
    const status = ref<string>('');
    const columns = ref<any[]>([]);
    const dataSourceQuarter = ref([]);
    const dataSourceMonthly = ref([]);
    const fileList = ref([]);
    const uploadFlag = ref(false);
    const handleUpload = (info: FileInfo) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        // 上传文件成功
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

    // 区域经理
    const regionManager = ref<string>('');
    const regionManagerList = ref<any[]>([]);

    const store = useStore();
    const dealerCode = computed(() => {
      return (store.state.user as any).organization.entityCode;
    });
    const brand = (props as any).brand;
    let buNameList: any[] = [];
    const brandIdMaps = {};

    const getManagerList = (name: string) => {
      return getManagerApi({
        dealerCode: dealerCode.value,
        programBu: name
        // programBu: buNameList[0].nameEn,
      });
      // .then((res: any) => {
      //     regionManagerList.value = res;
      // });
    };

    getAllBrands()
      .then((res: any) => {
        res.forEach((ele: any) => {
          brandIdMaps[ele.brandNameEn] = ele.id;
        });
      })
      .then((_: any) => {
        return findBuByBrand({ brandIds: brandIdMaps[brand] }).then((res: any) => {
          const noUseBus = ['Fleet', 'CPO', 'CompanyCar'];
          buNameList = res.filter((ele: any) => {
            return !noUseBus.includes(ele.nameEn);
          });
        });
      })
      .then((_) => {
        const fns: any[] = [];
        buNameList.map((bu: any) => {
          fns.push(getManagerList(bu.nameEn));
          // getManagerList(bu.nameEn).then((res: any) => {
          // if (res) {
          //     console.log(res);
          //     regionManagerList.value = [
          //         ...toRaw(regionManagerList.value),
          //         ...res,
          //     ];
          //     console.log(regionManagerList.value);
          // }
          // });
        });
        console.log(fns);
        Promise.all(fns).then((result: any) => {
          const usrIds: string[] = [];
          // const usrList = result.reduce((usrs: any, item: any) => {
          //     if (item && !usrIds.includes(item.usrid)) {
          //         usrIds.push(item.usrid);
          //         console.log([...usrs, ...item]);
          //         return [...usrs, ...item];
          //     }
          //     return usrs;
          // }, []);
          //! 优化
          const usrList = result.filter(Boolean).reduce((usrs: any, item: any) => {
            if (!usrIds.includes(item.usrid)) {
              usrIds.push(item.usrid);
              return [...usrs, ...item];
            }
            return usrs;
          }, []);
          regionManagerList.value = usrList;
        });
      });

    //批量上传总大小
    const uploadFileListSize = ref<number>(0);
    const uploadFileCount = ref<number>(0);
    const uploadfileList = ref([]);
    let end = 0;
    const submitUploadFlag = ref<boolean>(false);
    const beforeUpload = (file: FileItem, fileList: any) => {
      let fault = 0;
      uploadFileListSize.value = 0;
      end++;
      if (end !== fileList.length) {
        //没有上传文件的情况
        //return false;
        submitUploadFlag.value = false;
      }
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.type === 'image/jpeg' || file.type === 'application/pdf') {
          if (file.size / 1024 / 1024 < 5) {
            uploadFileListSize.value = uploadFileListSize.value + file.size / 1024 / 1024;
          } else {
            fault = 2;
          }
        } else {
          fault = 1;
        }
      }
      if (fileList.length > 100) {
        message.error('传输文件不能超过100个');
        end = 0;
        //return false;
        submitUploadFlag.value = false;
      } else if (fault === 1) {
        message.error('只能上传 jpg/pdf 格式的文件');
        end = 0;
        //return false;
        submitUploadFlag.value = false;
      } else if (fault === 2) {
        message.error('文件大小不能超过5M');
        end = 0;
        //return false;
        submitUploadFlag.value = false;
      } else {
        uploadfileList.value = fileList;
        uploadFileCount.value = fileList.length;
        end = 0;
        //return true;
        submitUploadFlag.value = true;
      }
    };
    const handlerConfirm = async () => {
      if (submitUploadFlag.value === false) {
        // 出错之后重置上传组件状态
        message.error('请上传延期证明材料');
        return;
      }
      if (!regionManager.value) {
        message.error('请选择区域经理');
        return;
      }
      const uploadFormData = new FormData();
      //uploadFormData.append('file', fileList.value)
      fileList.value.forEach((file: FileItem) => {
        uploadFormData.append('file', file.originFileObj);
      });
      const data = {
        recordId: props.recordId,
        regionManager: regionManager.value
      };
      try {
        const res = await postCallbackUpload(
          //uploadConfig,
          data,
          uploadFormData
        );
        // 上传之后重置上传组件状态
        //showUpload.value = true
        //percent.value = 0
        if (res.code === '0') {
          uploadFlag.value = false;
          message.success('submit success');
          //emit('handle-upload-success')
        } else {
          message.error('submit failed');
        }
      } catch (e) {
        // 上传出错重置上传组件状态
        //showUpload.value = true
        uploadFlag.value = false;
        //percent.value = 0
      }
    };

    return {
      columns,
      columnsAll,
      //rowSelection,
      handlerConfirm,
      dataSourceQuarter,
      dataSourceMonthly,
      status,
      withdrawCallbackUploadVisible,
      regionManagerList,
      regionManager,
      fileList,
      handleUpload,
      uploadFlag,
      beforeUpload
    };
  }
});
