
import { defineComponent, PropType } from "vue";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import { DealerFailReasonData } from "@/views/RetailValidation/Dealer/types";
import moment from "moment";
const failColumns = [
  {
    title: "失败原因",
    dataIndex: "comments",
    width: 350,
    slots: { customRender: "comments" }
  },
  {
    title: "反馈时间",
    dataIndex: "replyAt",
    width: 200,
    slots: { customRender: "replyAt" }
  }
];
export default defineComponent({
  components: { CloseCircleOutlined },
  props: {
    showFailFlag: {
      default: false,
      type: Boolean
    },
    failData: {
      default: () => [],
      type: Array as PropType<DealerFailReasonData[]>
    }
  },
  setup(props, context) {
    const failFlag = props.showFailFlag as boolean;
    const close = () => {
      context.emit("close-fail-reason");
    };
    return {
      moment,
      failColumns,
      failFlag,
      close
    };
  }
});
