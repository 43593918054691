
import { defineComponent, ref } from "vue";
import { getBasicDetail } from "@/API/rv/rvDealer";
import {
  EnumDealerApprovalStatus,
  EnumDealerCheckStatus
} from "@/views/RetailValidation/Dealer/types";
export default defineComponent({
  setup() {
    const data = ref({});
    const initData = () => {
      const params = {
        id: sessionStorage.getItem("detailRecordId")
      };
      getBasicDetail({ params }).then((res): void => {
        if (res.price) {
          res.price = res.price.replace(/,/g, "");
          res.price = parseFloat(res.price).toFixed(2);
          res.price =
            "￥" + res.price.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        }
        data.value = res;
      });
    };
    initData();
    return {
      data,
      EnumDealerApprovalStatus,
      EnumDealerCheckStatus
    };
  }
});
